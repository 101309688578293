export const dataPlaceholder = '-';

export const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToScroll: 4,
  arrows: false,
  lazyLoad: true,
  responsive: [
    { breakpoint: 250, settings: { slidesToShow: 1 } }, // 250
    { breakpoint: 250, settings: { slidesToShow: 1 } }, // 250
    { breakpoint: 500, settings: { slidesToShow: 1 } }, // 500
    { breakpoint: 750, settings: { slidesToShow: 2 } }, // 750
    { breakpoint: 1000, settings: { slidesToShow: 3 } }, // 1000
    { breakpoint: 1250, settings: { slidesToShow: 4 } }, // 1250
    { breakpoint: 1500, settings: { slidesToShow: 4 } }, // 1500
    { breakpoint: 1750, settings: { slidesToShow: 5 } }, // 1750
    { breakpoint: 2000, settings: { slidesToShow: 7 } }, // 2000
    { breakpoint: 2250, settings: { slidesToShow: 7 } }, // 2250
    { breakpoint: 2500, settings: { slidesToShow: 8 } }, // 2500
    { breakpoint: 2750, settings: { slidesToShow: 9 } }, // 2750
    { breakpoint: 3000, settings: { slidesToShow: 10 } }, // 3000
    { breakpoint: 3250, settings: { slidesToShow: 11 } }, // 3250
    { breakpoint: 3500, settings: { slidesToShow: 12 } }, // 3500
    { breakpoint: 3750, settings: { slidesToShow: 13 } }, // 3750
    { breakpoint: 4000, settings: { slidesToShow: 14 } }, // 4000
    { breakpoint: 4250, settings: { slidesToShow: 15 } },
  ],
};

export const bulkLotSliderSettings = {
  swipe: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    { breakpoint: 700, settings: { slidesToShow: 1 } }, // 500
    { breakpoint: 1100, settings: { slidesToShow: 2 } }, // 800
    { breakpoint: 1350, settings: { slidesToShow: 3 } }, // 1250
    { breakpoint: 1750, settings: { slidesToShow: 4 } },
    { breakpoint: 2000, settings: { slidesToShow: 5 } },
    { breakpoint: 3000, settings: { slidesToShow: 6 } },
  ],
};

export const stateNamesMap = new Map([
  ['new hampshire', 'nh'],
  ['new jersey', 'nj'],
  ['new mexico', 'nm'],
  ['new york', 'ny'],
  ['north carolina', 'nc'],
  ['north dakota', 'nd'],
  ['rhode island', 'ri'],
  ['south carolina', 'sc'],
  ['south dakota', 'sd'],
  ['west virginia', 'wv'],
  ['alabama', 'al'],
  ['alaska', 'ak'],
  ['arizona', 'az'],
  ['arkansas', 'ar'],
  ['california', 'ca'],
  ['colorado', 'co'],
  ['connecticut', 'ct'],
  ['delaware', 'de'],
  ['district of columbia', 'dc'],
  ['florida', 'fl'],
  ['georgia', 'ga'],
  ['hawaii', 'hi'],
  ['idaho', 'id'],
  ['illinois', 'il'],
  ['indiana', 'in'],
  ['iowa', 'ia'],
  ['kansas', 'ks'],
  ['kentucky', 'ky'],
  ['louisiana', 'la'],
  ['maine', 'me'],
  ['maryland', 'md'],
  ['massachusetts', 'ma'],
  ['michigan', 'mi'],
  ['minnesota', 'mn'],
  ['mississippi', 'ms'],
  ['missouri', 'mo'],
  ['montana', 'mt'],
  ['nebraska', 'ne'],
  ['nevada', 'nv'],
  ['ohio', 'oh'],
  ['oklahoma', 'ok'],
  ['oregon', 'or'],
  ['pennsylvania', 'pa'],
  ['tennessee', 'tn'],
  ['texas', 'tx'],
  ['utah', 'ut'],
  ['vermont', 'vt'],
  ['virginia', 'va'],
  ['washington', 'wa'],
  ['wisconsin', 'wi'],
  ['wyoming', 'wy'],
]);

export const stateAbbreviationsMap = new Map([
  ['ak', 'alaska'],
  ['al', 'alabama'],
  ['ar', 'arkansas'],
  ['az', 'arizona'],
  ['ca', 'california'],
  ['co', 'colorado'],
  ['ct', 'connecticut'],
  ['dc', 'district_of_columbia'],
  ['de', 'delaware'],
  ['fl', 'florida'],
  ['ga', 'georgia'],
  ['hi', 'hawaii'],
  ['ia', 'iowa'],
  ['id', 'idaho'],
  ['il', 'illinois'],
  ['in', 'indiana'],
  ['ks', 'kansas'],
  ['ky', 'kentucky'],
  ['la', 'louisiana'],
  ['ma', 'massachusetts'],
  ['md', 'maryland'],
  ['me', 'maine'],
  ['mi', 'michigan'],
  ['mn', 'minnesota'],
  ['mo', 'missouri'],
  ['ms', 'mississippi'],
  ['mt', 'montana'],
  ['nc', 'north carolina'],
  ['nd', 'north dakota'],
  ['ne', 'nebraska'],
  ['nh', 'new hampshire'],
  ['nj', 'new jersey'],
  ['nm', 'new mexico'],
  ['nv', 'nevada'],
  ['ny', 'new york'],
  ['oh', 'ohio'],
  ['ok', 'oklahoma'],
  ['or', 'oregon'],
  ['pa', 'pennsylvania'],
  ['ri', 'rhode island'],
  ['sc', 'south carolina'],
  ['sd', 'south dakota'],
  ['tn', 'tennessee'],
  ['tx', 'texas'],
  ['ut', 'utah'],
  ['va', 'virginia'],
  ['vt', 'vermont'],
  ['wa', 'washington'],
  ['wi', 'wisconsin'],
  ['wv', 'west virginia'],
  ['wy', 'wyoming'],
]);

const homeAndBrowseSections = [
  [
    '1',
    {
      name: 'BiddingOn',
      label: 'Bidding On',
      link: '/biddingon',
    },
  ],
  [
    '2',
    {
      name: 'BuyNow',
      label: 'Buy Now',
      link: '/search?hasPrice=true',
      // link: '/buynow',
    },
  ],
  [
    '3',
    {
      name: 'Events',
      label: 'Events',
      link: '/events',
    },
  ],
  [
    '4',
    {
      name: 'ItemsInWatchList',
      label: 'Watchlist',
      link: '/watchlist',
    },
  ],
  [
    '5',
    {
      name: 'LiveEvents',
      label: 'Live Events',
      link: null,
    },
  ],
  [
    '6',
    {
      name: 'Marketplaces',
      label: 'Marketplaces',
      link: '/marketplaces',
    },
  ],
  [
    '7',
    {
      name: 'NoSales',
      label: 'No-Sales',
      link: '/nosales',
    },
  ],
  [
    '8',
    {
      name: 'RecentlyAdded',
      label: 'Recently Added',
      link: '/search?sort=dateCreated_desc',
      // link: '/recentlyadded',
    },
  ],
  [
    '9',
    {
      name: 'RecentlyViewed',
      label: 'Recently Viewed',
      link: '/recentlyviewed',
    },
  ],
  [
    '10',
    {
      name: 'Retail',
      label: 'Retail',
      link: null,
    },
  ],
  [
    '11',
    {
      name: 'RetailEvents',
      label: 'Retail Events',
      link: null,
    },
  ],
  [
    '12',
    {
      name: 'RunningToday',
      label: 'Running Today',
      link: '/runningtoday',
    },
  ],
  [
    '13',
    {
      name: 'SellingNow',
      label: 'ReserveMet',
      link: '/sellingnow',
    },
  ],
  [
    '14',
    {
      name: 'Trade',
      label: 'Trade',
      link: null,
    },
  ],
  [
    '15',
    {
      name: 'TradeEvents',
      label: 'Trade Events',
      link: null,
    },
  ],
  [
    '16',
    {
      name: 'Featured',
      label: 'Featured',
      link: '/featured',
    },
  ],
  [
    '99',
    {
      name: 'Wishlist',
      label: 'Saved Searches',
      link: '/savedsearches',
    },
  ],
];

// a couple of these have configKeys which means they also have to have that enabled in react config.
export const mpHomePageSectionsMap = new Map(homeAndBrowseSections);

export const mpBrowseLinksMap = new Map(homeAndBrowseSections);

export const mpDetailsPageSectionsMap = new Map([
  ['1', 'AccuTrade'],
  ['2', 'Announcements'],
  ['3', 'BidHistory'],
  ['4', 'Booksheet'],
  ['5', 'Condition'],
  ['6', 'Diagnostic'],
  ['7', 'EventDetails'],
  ['8', 'Gallery'],
  ['9', 'History'],
  ['10', 'Offers'],
  ['11', 'Options'],
  ['12', 'VehicleDetails'],
]);

export const mpDetailsPageFieldsMap = new Map([
  ['1', 'Engine'],
  ['2', 'ExtColor'],
  ['3', 'IntColor'],
  ['4', 'InteriorType'],
  ['5', 'LicenseNumber'],
  ['6', 'LotNumber'],
  ['7', 'Mileage'],
  ['8', 'OutrightPrice'],
  ['9', 'SleeperSize'],
  ['10', 'SleeperType'],
  ['11', 'StockNumber'],
  ['12', 'Transmission'],
  ['13', 'VIN'],
  ['14', 'Warranty'],
  ['15', 'DriveTrain'],
  ['16', 'CRGrade'],
  ['17', 'SleeperBunks'],
  ['18', 'SerialNumber'], // may change
]);

export const mpDetailsPageButtonsMap = new Map([
  ['1', 'Bid'],
  ['2', 'Buy'],
  ['3', 'MMR'],
  ['4', 'Notes'],
  ['5', 'Offer'],
  ['6', 'ReserveItem'],
  ['7', 'Watchlist'],
  ['8', 'Retail'],
]);

export const mp3rdPartyIntegratorButtonsMap = new Map([
  ['1', 'Autoniq'],
  ['2', 'AutoCheck'],
  ['3', 'CARFAX'],
  ['4', 'Accu-Trade'],
  ['5', 'Carbly'],
]);

export const mpSortOptionsMap = new Map([
  [
    '1',
    {
      label: 'Relevance',
      field: '_score',
      order: 'desc',
    },
  ],
  [
    '2',
    {
      label: 'Lot Number - Ascending',
      field: 'lotNumber.raw',
      order: 'asc',
    },
  ],
  [
    '3',
    {
      label: 'Lot Number - Descending',
      field: 'lotNumber.raw',
      order: 'desc',
    },
  ],
  [
    '4',
    {
      label: 'Make',
      field: 'make.raw',
      order: 'asc',
    },
  ],
  [
    '5',
    {
      label: 'Model',
      field: 'model.raw',
      order: 'asc',
    },
  ],
  [
    '6',
    {
      label: 'Year - Latest First',
      field: 'year',
      order: 'desc',
    },
  ],
  [
    '7',
    {
      label: 'Year - Oldest First',
      field: 'year',
      order: 'asc',
    },
  ],
  [
    '8',
    {
      label: 'Odometer - Lowest First',
      field: 'mileage',
      order: 'asc',
    },
  ],
  [
    '9',
    {
      label: 'Odometer - Highest First',
      field: 'mileage',
      order: 'desc',
    },
  ],
  [
    '10',
    {
      label: 'Buy Now Price - Lowest First',
      field: 'outrightPrice',
      order: 'asc',
    },
  ],
  [
    '11',
    {
      label: 'Buy Now Price - Highest First',
      field: 'outrightPrice',
      order: 'desc',
    },
  ],
  [
    '12',
    {
      label: 'Retail Price - Lowest First',
      field: 'feedPrice',
      order: 'asc',
    },
  ],
  [
    '13',
    {
      label: 'Retail Price - Highest First',
      field: 'feedPrice',
      order: 'desc',
    },
  ],
  [
    '14',
    {
      label: 'Event Name',
      field: 'eventName.raw',
      order: 'asc',
    },
  ],
  [
    '15',
    {
      label: 'Seller Name',
      field: 'sellerName.raw',
      order: 'asc',
    },
  ],
  [
    '16',
    {
      label: 'Recently Added',
      field: 'dateCreated',
      order: 'desc',
    },
  ],
  [
    '17',
    {
      label: 'Ending Soonest',
      field: 'epochEndTime',
      order: 'asc',
    },
  ],
]);

export const mpSearchColumnsMap = new Map([
  [
    '1',
    {
      panel: 'Search Vehicles', // HyperSearch
      defaultCollapsed: false,
      collapsable: false,
    },
  ],
  [
    '2',
    {
      panel: 'Location',
      field: 'marketplaceName.raw',
      translations: { All: 'All Locations' },
    },
  ],
  [
    '3',
    {
      panel: 'Event',
      field: 'eventName.raw',
      translations: { All: 'All Events' },
    },
  ],
  [
    '4',
    {
      panel: 'Marketplace Type',
      field: 'marketplaceType.raw',
      translations: { '': 'Not Specified' },
    },
  ],
  [
    '5',
    {
      panel: 'Seller',
      field: 'sellerName.raw',
    },
  ],
  [
    '6',
    {
      panel: 'Distance',
      field: '',
    },
  ],
  [
    '7',
    {
      panel: 'Year',
      field: 'year',
    },
  ],
  [
    '8',
    {
      panel: 'Make',
      field: 'make.raw',
      translations: { All: 'All Makes', '': 'Not Specified' },
    },
  ],
  [
    '9',
    {
      panel: 'Model',
      field: 'model.raw',
      translations: { All: 'All Models', '': 'Not Specified' },
    },
  ],
  [
    '10',
    {
      panel: 'Image',
      id: 'hasImage',
      label: 'Has Image',
    },
  ],
  [
    '11',
    {
      panel: 'Condition Report',
    },
  ], // CR
  [
    '12',
    {
      panel: 'CR Grade',
      field: '',
    },
  ], //
  [
    '13',
    {
      panel: 'Buy Now Price',
      field: 'outrightPrice',
    },
  ], // Price
  [
    '14',
    {
      panel: 'Retail Price',
      field: 'feedPrice',
    },
  ], // Price
  [
    '15',
    {
      panel: 'Odometer',
      field: 'mileage',
    },
  ],
  [
    '16',
    {
      panel: 'Drive',
      field: 'drive.raw',
    },
  ],
  [
    '17',
    {
      panel: 'Color',
      field: 'exteriorColor.raw',
    },
  ],
  [
    '18',
    {
      panel: 'Options',
      field: 'options',
    },
  ],
  [
    '19',
    {
      panel: 'Engine',
      field: 'engine.raw',
    },
  ],
  [
    '20',
    {
      panel: 'Transmission',
      field: 'transmission.raw',
    },
  ],
  [
    '21',
    {
      panel: 'No-Sales',
    },
  ],
]);

// NOTE - comments are awg descriptions, which may be different from Marketplace descriptions
export const alertTypes = [
  { id: 1001, description: 'New vehicle alert for buyers' }, // "New vehicle alert for buyers"
  { id: 1002, description: 'Wish list alert for buyers' }, // "Wish list alert for buyers"
  { id: 1003, description: 'Re-list alert for sellers' }, // "Re-list alert for sellers"
  { id: 1004, description: 'Received Offer Alerts' }, // "Offers received alert for sellers" ***
  { id: 1005, description: 'Offers made alert for buyers' }, // "Placed Offer Alerts" ***
  { id: 1006, description: 'Outbid Alert' }, // "Outbid alert for buyers" ***
  { id: 1007, description: 'Sold Confirmation Alerts' }, // "Sales confirmation for sellers" ***
  { id: 1008, description: 'Purchase Confirmation Alerts' }, // "Purchase confirmation for buyers" ***
  { id: 1009, description: 'If sale alert for buyers and sellers' }, // "If sale alert for buyers and sellers"
  { id: 1010, description: 'End of listing alert for buyers and sellers' }, // "End of listing alert for buyers and sellers"
  { id: 1011, description: 'Price drop alert for buyers' }, // "Price drop alert for buyers"
  { id: 1012, description: 'Event start alert for buyers and sellers' }, // "Event start alert for buyers and sellers"
  { id: 1013, description: 'Event end alert for buyers and sellers' }, // "Event end alert for buyers and sellers"
  { id: 1014, description: 'Condition report change alert for buyers' }, // "Condition report change alert for buyers"
  { id: 1015, description: 'Purchase recommendation alert for buyers' }, // "Purchase recommendation alert for buyers"
  { id: 1016, description: 'Watchlist Item Live Alert' }, // "Live auction watch list alert" ***
  { id: 1017, description: 'Vehicles ready for release alert for sellers' }, // "Vehicles ready for release alert for sellers"
  { id: 1018, description: 'Negotiate price alert for buyers' }, // "Negotiate price alert for buyers"
  { id: 1019, description: 'Negotiate price alert for sellers' }, // "Negotiate price alert for sellers"
  { id: 1020, description: 'Transport requested alert for transporter' }, // "Transport requested alert for transporter"
  { id: 1021, description: 'Transport scheduled alert for buyers and sellers' }, // "Transport scheduled alert for buyers and sellers"
  { id: 1022, description: 'Item delivered alert for buyers' }, // "Item delivered alert for buyers"
  { id: 1023, description: 'Item accepted alert for seller' }, // "Item accepted alert for seller"
  { id: 1024, description: 'Mediation alert for buyers and sellers' }, // "Mediation alert for buyers and sellers"
  {
    id: 1025,
    description: 'Payment status update alert for buyers and sellers',
  }, // "Payment status update alert for buyers and sellers"
  { id: 1026, description: 'Title status update alert for buyers and sellers' }, // "Title status update alert for buyers and sellers"
  { id: 1027, description: 'Unwind sale alert buyers and sellers' }, // "Unwind sale alert buyers and sellers"
  { id: 1028, description: 'Received Bid Alerts' }, // "Bid received alert for sellers" ***
  { id: 1029, description: 'Placed Bid Alerts' }, // "Bid made alert for buyers" ***
  { id: 1030, description: 'Registration Confirmation Email' }, // "Registration Confirmation Email"
  { id: 2001, description: 'New market vehicle report for buyers' }, // "New market vehicle report for buyers"
  { id: 2002, description: 'Wish list matches report for buyers' }, // "Wish list matches report for buyers"
  { id: 2003, description: 'Re-list report for sellers' }, // "Re-list report for sellers"
  { id: 2004, description: 'Offers or bids received report for sellers' }, // "Offers or bids received report for sellers"
  { id: 2005, description: 'Offers or bids made report for buyers' }, // "Offers or bids made report for buyers"
  { id: 2007, description: 'Sales report for sellers' }, // "Sales report for sellers"
  { id: 2008, description: 'Purchase report for buyers' }, // "Purchase report for buyers"
  { id: 2009, description: 'If sale report for sellers' }, // "If sale report for sellers"
  { id: 2010, description: 'End of Listing report for buyers and sellers' }, // "End of Listing report for buyers and sellers"
  { id: 2011, description: 'Price drop alert for buyers' }, // "Price drop alert for buyers"
  { id: 2015, description: 'Purchase recommendation alert for buyers' }, // "Purchase recommendation alert for buyers"
  { id: 3001, description: 'Auction Registration Confirmation Email' }, // "Auction Registration Confirmation Email"
  { id: 3002, description: 'Change in Announcements Alert' }, // "Change in Announcements Alert"
];

export const deviceTypes = [
  { id: 1, description: 'Email' },
  { id: 2, description: 'Text Email' },
  { id: 3, description: 'Email Text Msg' },
  { id: 4, description: 'Phone' },
  { id: 5, description: 'Fax' },
  { id: 6, description: 'SMS Phone' },
  { id: 7, description: 'Mobile' },
];

export const SST_FIRST_OFFER = 'SST First Offer';

export const breadcrumbLabels = {
  // pathname: label
  '/item/': 'Vehicle Details',

  '/inventory': 'Seller Listed',
  '/inventory/listed': 'Seller Listed',
  '/inventory/unlisted': 'Seller Unlisted',
  '/inventory/bids': 'Seller Bids',
  '/inventory/offers': 'Seller Offers',
  '/inventory/sold': 'Seller Sold',
  '/inventory/arbitration': 'Seller Arbitration',
  '/inventory/arbs': 'Seller Arbitration',
  '/inventory/titles': 'Seller Titles',
  '/inventory/transportation': 'Seller Transportation',
  '/inventory/ifs': 'Seller If Sales',
  '/inventory/hyperlaunch listed': 'Hyperlaunch Listed',
  '/inventory/hyperlaunch unlisted': 'Hyperlaunch Unlisted',
  '/inventory/caroffers': 'Car Offers',

  '/status': 'Buyer Bids',
  '/status/bids': 'Buyer Bids',
  '/status/offers': 'Buyer Offers',
  '/status/purchased': 'Buyer Purchased',
  '/status/arbitration': 'Buyer Arbitration',
  '/status/arbs': 'Buyer Arbitration',
  '/status/titles': 'Buyer Titles',
  '/status/transportation': 'Buyer Transportation',
  '/status/ifs': 'Buyer If Sales',

  '/savedsearches': 'Saved Searches',
  '/myactivity': 'Activity Timeline',
};

export const urlsMap = new Map([
  ['CARFAX', 'https://auth.carfax.com/v2'],
  ['RAVIN', 'http://integration2.staging.ravin-ai.com/inspector2'],
  ['RAVIN-QA', 'https://qa11.qa.ravin-ai.com/inspector2/page/guest'],
  ['ACCUTRADE', 'https://www.accu-trade.com'],
  ['AUTO_IPACKET', 'https://djapi.autoipacket.com/v1/vdp/website-plugin'],
  ['HOLMAN', 'https://holman.iasmarketplace.com/api'],
  ['AUTOCHECK', 'http://www.autocheck.com/vehiclehistory'],
  [
    'SPINCAR',
    'https://find-spin-api.spincar.com/provider-api/integratedauctionsolutions/iasmarketplace.com',
  ],
  [
    'IMAGE_ERROR',
    'https://cdn.integratedauctionsolutions.com/marketplace/image_error.png',
  ],
  [
    'IMAGE_ERROR_DARK',
    'https://cdn.integratedauctionsolutions.com/marketplace/image_error_dark.png',
  ],
  [
    'PLATFORM_TERMS_OF_USE',
    'https://cdn.integratedauctionsolutions.com/marketplace/platform_terms_of_use.pdf',
  ],
  [
    'PLATFORM_PRIVACY_POLICY',
    'https://cdn.integratedauctionsolutions.com/marketplace/platform_privacy_policy.pdf',
  ],
  [
    'AA_ENROLLMENT',
    'https://selfservice.auctionaccess.com/#enroll'
  ]
]);

// Displayed when a non-AA user attempts an action on a AA-required listing
export const toasterAlerts = {
  auctionAccess: {
    userMustRegister: {
      TITLE: 'AuctionACCESS is required',
      BODY: 
        `You will need to register with AuctionACCESS and add the credentials ` +
        `to your user profile.`,
      ENROLL_URL_TEXT: `Click here to sign up`,
    }
  }
}