import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import LazyLoad from 'react-lazyload';
import ImageGallery from 'react-image-gallery';
import Fullscreen from './imageGallery/Fullscreen';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { find, get } from 'lodash';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import QRCode from 'qrcode.react';
import CountdownTimerVLI from './CountdownTimerVLI';
import BuyModal from '../components/BuyModal';
import BidModal from '../components/BidModal';
import Lights from '../components/Lights';
import IDHorizontal from './common/IDHorizontal';
import Fees from './common/Fees';
import {
  loadAddWatchList,
  loadElasticSearchDistance,
  loadItem,
  loadItemOffer,
  loadRemoveWatchList,
  loadTransportationTypes,
  loadWatchlistES,
  loadWatchlistUserItems,
  resetOffer,
  resetBidHistory,
  loadWatchlistObject,
} from '../actions';
import {
  commafy,
  commafyCurrency,
  compose,
  findDistanceUnits,
  findMileageUnits,
  getAttributeLabelAndValue,
  getConfig,
  getTemplate,
  isDST,
  makeAssetLink,
  makeBulkLotBoxShadow,
  showBidButton,
  showBuyNowButton,
  showOfferButton,
} from '../utils/helpers';
import withRouter from '../hocs/withRouter';
import { dataPlaceholder, urlsMap } from '../utils/constants';
import { localization } from '../utils/localization';
import BulkLotBidModal from './BulkLotBidModal';
import BulkLotItemsModal from './BulkLotItemsModal';
import OfferModal from './OfferModal';
import { aaRequiredToaster } from './auctionAccess';

// let styles = {};
const bulkLotBoxShadow = makeBulkLotBoxShadow();
const imageHeight = 220;
const DISABLE_DISTANCE_IN_DEV = false;
window.$ = window.jQuery = $;
require('bootstrap');
/**
 * @todo - 2024.08.23 -- Refactor and remove jQuery/bootstrap. 
 *      - Converting inline-styles to classes 
 *          for responsive UI effort
 * 
 */
class VehicleListItem extends Component {
  constructor(props) {
    super(props);
    const parentMarketplace = find(
      props.marketplaces.marketplaceList,
      mp => mp.marketplaceId == getConfig('marketplaceId')
    );
    const mpSearchColumns = get(parentMarketplace, 'mpSearchColumns', '')
      .split(',')
      .filter(col => col);

    const images = props.vehicleData.images.split(',').filter(i => i);
    if (!images.length) {
      images.push(
        props.vehicleData.mainImage || makeAssetLink('placeholder.png')
      );
    }

    this.state = {
      index: 0,
      showGallery: false,
      offerAmount: '',
      selectedAccount: '',
      watchlist: 'check',
      watchlistButtonDisabled: false,
      devDistance: undefined,
      googleMapsDistance: undefined,
      transportationDistance: undefined,
      esGeoDistance: undefined,
      vehicleDataDistance: undefined,
      distance: undefined,
      distanceMethod: undefined,
      images,
      dynamicData:
        getConfig('enableDynamicAttributes') &&
        get(props.vehicleData, 'attributes', []).some(
          attribute => attribute.inDetail
        ),
      bulkLotItems: [],
      hideSeller: mpSearchColumns.length && !mpSearchColumns.includes('5'), // don't have an mp feature atm
      isBidModalOpen: false,
      isOfferModalOpen: false,
      isBuyNowModalOpen: false,
      isBulkLotItemsModalOpen: false,
      isBulkBidModalOpen: false,
    };
  }

  componentDidMount() {
    const selectedAccount = localStorage.getItem('selectedAccount');

    if (selectedAccount) {
      this.setState({ selectedAccount });
    }

    this.loadDistance();
    this.setBulkLot(this.props.vehicleData);
  }

  componentDidUpdate(prevProps, prevState) {
    this.loadDistance(prevProps);
  }

  setBulkLot = item => {
    if (item.subItemDetails.length) {
      const subItems = [...item.subItemDetails];
      subItems.unshift({
        listingId: item.id,
        cardTitle: [
          item.year || '',
          item.make || '',
          item.model || '',
          item.series || '',
        ].join(' '),
        location: item.itemCity + ', ' + item.itemState,
        vin: item.vin || item.vIN,
      });

      this.setState({
        bulkLotItems: subItems,
      });
    }
  };

  loadGoogleMapDistance(prevProps = {}) {
    // this method is the new default method for getting distance because
    // it is replicates how the transportation-estimate api calculates it's shipping distance
    // called individually per vehicle whenever the "userSelectedLocation" changes

    if (
      this.props.userSelectedLocation.address !==
        get(prevProps.userSelectedLocation, 'address') ||
      this.state.googleMapsDistance === undefined
    ) {
      this.setState(
        {
          googleMapsDistance: null,
          distance: null,
        },
        () => {
          const origins =
            this.props.vehicleData.itemPostalCode ||
            `${this.props.vehicleData.itemCity}+${this.props.vehicleData.itemState}`;

          const destinations =
            this.props.userSelectedLocation.zipcode ||
            (this.props.userSelectedLocation.address || '')
              .replace(/[*,]/g, '')
              .trim()
              .replace(/ /g, '+');

          if (!origins || !destinations) return null;

          const googleMapsDistanceService =
            new window.google.maps.DistanceMatrixService();
          googleMapsDistanceService.getDistanceMatrix(
            {
              origins: [origins],
              destinations: [destinations],
              travelMode: 'DRIVING',
              unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            },
            (response, status) => {
              if (response && status === 'OK') {
                const googleMapsDistance =
                  get(response, 'rows[0].elements[0].distance.value', '') *
                    0.00062137 || null;

                this.setState({
                  googleMapsDistance,
                  distance: googleMapsDistance,
                  distanceMethod: 'googleMapsDistance',
                });
              }
            }
          );
        }
      );
    }
  }

  loadTransportationDistance(prevProps = {}) {
    // this method gets the distance from the transportation-estimate api directly
    // we're not using this in lieu of the google maps distance
    if (
      this.props.userSelectedLocation.accountId !==
        get(prevProps.userSelectedLocation, 'accountId') ||
      this.state.transportationDistance === undefined
    ) {
      this.setState(
        {
          transportationDistance: null,
          distance: null,
        },
        () => {
          if (!this.props.userSelectedLocation.accountId) return null;

          this.props
            .loadTransportationTypes(
              getConfig('marketplaceId'),
              this.props.vehicleData.id,
              this.props.userSelectedLocation.accountId
            )
            .then(({ response }) => {
              if (response.wsStatus === 'Success') {
                const transportationDistance =
                  get(
                    find(response.items, item => item.distance),
                    'distance'
                  ) || null;

                this.setState({
                  transportationDistance,
                  distance: transportationDistance,
                  distanceMethod: 'transportationDistance',
                });
              }
            });
        }
      );
    }
  }

  loadESGeoDistance(prevProps = {}) {
    // we're not using this anymore because it doesn't match the shipping distance. This method passes the user's coordinates to
    // elasticsearch and gets plane (or arc) distance.  It is called per vehicle.
    if (!this.props.userSelectedLocation.location) {
      return null;
    }

    if (
      this.props.userSelectedLocation.address !==
        get(prevProps.userSelectedLocation, 'address') ||
      this.state.esGeoDistance === undefined
    ) {
      this.setState(
        {
          esGeoDistance: null,
          distance: null,
        },
        () => {
          if (
            !this.props.vehicleData.id ||
            !this.props.userSelectedLocation.location.lat ||
            !this.props.userSelectedLocation.location.lng ||
            !findDistanceUnits(this.props.marketplaceFeatures)
          ) {
            return null;
          }

          this.props
            .loadElasticSearchDistance(
              this.props.vehicleData.id,
              this.props.userSelectedLocation.location.lat,
              this.props.userSelectedLocation.location.lng,
              findDistanceUnits(this.props.marketplaceFeatures)
            )
            .then(({ response }) => {
              const esGeoDistance =
                get(response, 'hits.hits[0].fields.distance[0]') || null;

              this.setState({
                esGeoDistance,
                distance: esGeoDistance,
                distanceMethod: 'esGeoDistance',
              });
            });
        }
      );
    }
  }

  loadVehicleDataDistance(prevProps = {}) {
    // this method uses the distance that is calculated by elasticsearch on the Search page
    // with Searchkit.  We use it when (1) when we are on the Search page and (2) when the user
    // selects a location that is NOT from the account list.  It's less expensive than the
    // google maps and we don't need it to exactly match shipping distance

    const vehicleDataDistance = get(this.props.vehicleData, 'distance', [])[0];
    const prevDistance = get(prevProps.vehicleData, 'distance', [])[0];

    if (
      vehicleDataDistance !== prevDistance ||
      this.state.vehicleDataDistance === undefined
    ) {
      this.setState({
        vehicleDataDistance: vehicleDataDistance || null,
        distance: vehicleDataDistance || null,
        distanceMethod: 'vehicleDataDistance',
      });
    }
  }

  loadDevDistance(prevProps) {
    if (this.state.devDistance) return;

    const devDistance = 999999;

    this.setState({
      devDistance,
      distance: devDistance,
      distanceMethod: 'devDistance',
    });
  }

  loadDistance(prevProps = {}) {
    // to avoid google charges we can limit calls during development
    if (DISABLE_DISTANCE_IN_DEV && process.env['MY_NODE'] === 'development') {
      this.loadDevDistance(prevProps);
    } else if (this.props.category !== 'Search') {
      this.loadESGeoDistance(prevProps);
    } else if (!this.props.userSelectedLocation.accountId) {
      this.loadVehicleDataDistance(prevProps);
    } else {
      this.loadGoogleMapDistance(prevProps);
    }
  }
  /** Determines whether or not user needs to register for Auction Access
  *  to take action (buy, bid, offer) on an item.
  * 
  * @description Returns `true` if: 
  *  - `props.item.item.requiresAuctionAccess` is `true` 
  *  - `props.userProfile.user.hasAuctionAccess` is `false`
  * 
  * @returns {boolean}
  */
  isAuctionAccessNeeded = () => {
    try {
      const hasAuctionAccess = get(this.props.userProfile, 'user.hasAuctionAccess', false);
      const requiresAuctionAccess = get(this.props.vehicleData, 'requiresAuctionAccess');
      return Boolean(requiresAuctionAccess && !hasAuctionAccess);
    } catch (isAuctionAccessNeededError) {
      console.error(isAuctionAccessNeededError?.message || isAuctionAccessNeededError)
      return false;
    }
  }

  handleAutoCheck = () => {
    var width = Math.min(1024, window.screen.width * 0.85);
    var height = 450;
    var left = window.screen.width / 2 - (width / 2 + 10);
    var top = window.screen.height / 2 - (height / 2 + 50);
    var url =
      'http://www.autocheck.com/vehiclehistory/?vin=' +
      this.props.vehicleData.vIN;
    window.open(
      url,
      '',
      'status=no,height=' +
        height +
        ',width=' +
        width +
        ',resizable=yes,left=' +
        left +
        ',top=' +
        top +
        ',screenX=' +
        left +
        ',screenY=' +
        top +
        ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no'
    );
  };

  handleCardClick = e => {
    e.preventDefault();
    // persist scroll position on Search page
    if (this.props.category === 'search') {
      const searchScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (searchScroll) sessionStorage.setItem('searchScroll', searchScroll);
    }

    // gives the VDP the list
    if (this.props.navItems) {
      sessionStorage.setItem('navItems', this.props.navItems);
    }

    const path = `/item/${this.props.vehicleData.id}`;
    this.props.navigate(path);
  };

  handleModal = e => {
    e.preventDefault();
    e.stopPropagation();
    const aaIsRequired = this.isAuctionAccessNeeded();
    const type = e.currentTarget.getAttribute('data-type');
    const mpId = getConfig('marketplaceId');
    const itemId = this.props.vehicleData.id;
    const modalId = this.determineModalId(itemId);

    // user does not have AA + item is AA-only
    if (aaIsRequired && ['bid','offer','buy'].includes(type)) {
      aaRequiredToaster();
      return;
    }

    switch (type) {
      case 'bid': {
        this.props.loadItem(mpId, itemId);
        this.setState({ isBidModalOpen: true });
        break;
      }
      case 'offer': {
        // Reminder that offers are not handled thru the modal when viewing VehicleListItems
        // 2024.09.04 - (MP-45) Enabling Offer modal until inline issues are addressed
        this.props.loadItem(mpId, itemId);
        this.setState({ isOfferModalOpen: true });
        break;
      }
      case 'buy': {
        this.props.loadItem(mpId, itemId);
        this.setState({ isBuyNowModalOpen: true });
        break;
      }
      case 'bulkBid': {
        this.props.loadItem(mpId, itemId);
        // $(`#bulkLotBidModal${modalId}`).appendTo(document.body).modal('show');
        this.setState({ isBulkBidModalOpen: true });
        break;
      }
      case 'bulkList': {
        this.props.loadItem(mpId, itemId);
        // $(`#bulkLotItemsModal${modalId}`).appendTo(document.body).modal('show');
        this.setState({ isBulkLotItemsModalOpen: true });
        break;
      }
      default:
        return null;
    }
  };

  handleWatchlistAction = action => {
    // call watchlist-add or watchlist-remove api
    this.setState({ watchlistButtonDisabled: true }, () => {
      const mpId = getConfig('marketplaceId');
      const message =
        action === 'add'
          ? 'Added to your watchlist'
          : 'Removed from your watchlist';

      const watchlistAction =
        action === 'add'
          ? this.props.loadAddWatchList
          : this.props.loadRemoveWatchList;

      watchlistAction(mpId, this.props.vehicleData.id)
        .then(({ response }) => {
          if (response.wsStatus === 'Success') {
            this.props.loadWatchlistObject(mpId);
            this.setState({ watchlistButtonDisabled: false }, () =>
              toastr.success('Success', message, { timeOut: 2000 })
            );
          } else {
            toastr.error('Error', response.wsMessage, { timeOut: 2000 });
          }
        })
        .catch(error => {
          toastr.error('Error');
          console.error(error, { timeOut: 2000 });
        });
    });
  };

  loadAccounts = () => {
    if (
      this.props.userProfile.user &&
      this.props.marketplaceFeatures.features
    ) {
      const accounts = this.props.userProfile.user.accountList
        .filter(account => {
          const isPersonalAccount = account.accountNumber.includes('@');
          const hideAccountsWithoutBidderBadges =
            this.props.marketplaceFeatures.features.includes('469');

          return isPersonalAccount ||
            (hideAccountsWithoutBidderBadges && account.badgeNumber === null)
            ? false
            : true;
        })
        .map((account, index) => {
          return (
            <option key={index} value={account.userId}>
              {account.accountName} - {account.accountNumber} -{' '}
              {account.state ? ', ' : ''}
              {account.state}
            </option>
          );
        });

      return accounts;
    }
  };

  handleAmount = e => {
    const offerAmount = e.target.validity.valid
      ? e.target.value
      : this.state.offerAmount;

    this.setState({
      offerAmount: offerAmount,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    return false;
  };

  handleOffer = e => {
    this.props.loadItemOffer(
      getConfig('marketplaceId'),
      this.props.vehicleData.id,
      this.props.vehicleData.listingStatusId === 1 ? 4 : 102,
      this.state.offerAmount,
      this.state.selectedAccount
    );
  };

  handleAccountSelection = e => {
    const selectedAccount = e.target.value;
    this.setState({ selectedAccount });
    localStorage.setItem('selectedAccount', selectedAccount);
  };

  renderDaysInMarketplace() {
    const { vehicleData } = this.props;

    // TODO: - what's going on here?
    if (vehicleData.startDate) {
      return <span />;
    }
    return null;
  }

  renderQRCode(vin) {
    const hideQRCode = (this.props.marketplaceFeatures.features || '')
      .split(',')
      .includes('534');

    if (hideQRCode) return null;

    return (
      <div className="pull-right vehicle-list-view_item__qrcode">
        <QRCode value={vin} renderAs="svg" size={60} />
        <div style={{ textAlign: 'center', fontSize: 12 }}>Scan VIN</div>
      </div>
    );
  }

  renderVin() {
    if (getConfig('localization') === 'en-us') {
      return (
        <span className="col-sm-6">
          <strong>VIN:</strong> {this.props.vehicleData.vIN}
        </span>
      );
    }
  }

  renderLights(lights) {
    // example: 'Red,Green'
    return <Lights lights={lights} style={{ marginRight: 10 }} />;
  }

  renderImageBox(isBulkLot) {
    return (
      <div 
        // style={styles.imageBox}
        className='vehicle-list-view_item__image-wrapper'
      >
        {this.renderBulkLotBadge()}
        {this.renderStatus()}
        {this.renderLotNumberBadge()}
        {this.renderCRBadge()}
        {this.renderHighBidderBadge()}
        {this.renderRetailPriceBadge()}
        {this.render360Badge()}
        {this.renderImages()}
      </div>
    );
  }

  renderStatus() {
    const statusId = this.props.vehicleData.statusId;
    const status2 = this.props.vehicleData.status2Id;
    let text = '';

    switch (this.props.vehicleData.listingStatus) {
      case 'Sold':
        text = 'SOLD';
        break;
      case 'If Sale':
        text = 'If Sale';
        break;
      case 'Cancelled':
        text = 'Removed';
        break;
      case 'Sale or Return':
        text = 'Not Available';
        break;
      case 'Ready For Release':
        text = 'Unlisted';
        break;
      case 'No Sale':
        text = 'No Sale';
        break;
      default:
        break;
    }

    if ((statusId === 4 || statusId === 7) && status2 === 103) {
      text = 'Not Available';
    }

    if (!text) return null;

    return (
      <div className="ribbon">
        <span>{text}</span>
      </div>
    );
  }

  renderImages() {
    const useVehicleCustom = getConfig('enableDynamicAttributes');
    const { images, showGallery } = this.state;
    const { vehicleData } = this.props;

    return (
      <div 
        // style={styles.imageContainer}
        className='vehicle-list-view_item__image-container'
      >
        {showGallery ? (
          <ImageGallery
            ref={el => (this.galleryRef = el)}
            items={images.map((image, index, list) => ({
              original: image || '',
              thumbnail: image || '',
              description: `${index + 1} of ${list.length}`,
            }))}
            onErrorImageURL={urlsMap.get('IMAGE_ERROR_DARK')}
            showThumbnails={true}
            showPlayButton={true}
            showNav={true}
            showFullscreenButton={true}
            slideInterval={2500}
            lazyLoad
            onScreenChange={changed => {
              this.setState({ showGallery: changed });
            }}
            renderFullscreenButton={(onClick, isFullscreen) => (
              <Fullscreen
                onClick={onClick}
                isFullscreen={isFullscreen}
                callback={() => {
                  this.galleryRef.exitFullScreen();
                  this.setState({ showGallery: false });
                }}
              />
            )}
          />
        ) : (
          <LazyLoad 
            className='vehicle-list-view_item__image' 
            // height={imageHeight}
            >
            <img
              className='vehicle-list-view_item__image' 
              style={useVehicleCustom ? styles.dynamicImage : styles.image}
              onClick={() => {
                this.setState({ showGallery: true }, () => {
                  this.galleryRef.fullScreen();
                });
              }}
              src={vehicleData.mainImage}
              onError={e => {
                e.target.src = makeAssetLink('placeholder.png');
              }}
              role="presentation"
              alt=""
            />
          </LazyLoad>
        )}
      </div>
    );
  }

  renderVehicleInfo() {
    return (
      <div 
        // style={styles.vehicleInfo}
        className='vehicle-list-view_item__vehicle_info '
      >
        {this.renderVehicleInfoTop()}
        <hr style={{ margin: '5px 0' }} />
        {this.renderVehicleInfoBottom()}
      </div>
    );
  }

  renderVehicleInfoTop() {
    const { vehicleData } = this.props;

    const YMMT = [
      vehicleData.year || '',
      vehicleData.make || '',
      vehicleData.model || '',
      vehicleData.series || '',
    ].join(' ');

    const isBulkLot =
      vehicleData.hasSubListings && vehicleData.subItemDetails.length > 0;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="underline-on-hover" style={{ cursor: 'pointer' }}>
            <div
              style={{ fontSize: 18, fontWeight: 500 }}
              onClick={this.handleCardClick}
            >
              {YMMT}
            </div>
            <div>{!isBulkLot && vehicleData.bodyStyle}</div>
          </div>

          <Link to={`/search?eventName[0]=${vehicleData.eventName}`}>
            <span style={styles.infoLabel}>Event:</span>
            <span>{vehicleData.eventName}</span>
          </Link>
        </div>

        <div
          style={{
            // top right
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {this.renderLights(vehicleData.lights)}
          {this.renderQRCode(vehicleData.vIN)}
        </div>
      </div>
    );
  }

  renderVehicleInfoBottom() {
    const { vehicleData } = this.props;
    const { hideSeller } = this.state;

    const mileageUnits = findMileageUnits(vehicleData);

    return (
      <div className="vehicle-feature-list">
        <div className="col-xs-5 no-padding">
          <ul className="list-unstyled">
            <li>
              <IDHorizontal
                vin={vehicleData.vIN}
                stockNumber={vehicleData.stockNumber}
              />
            </li>

            <li>
              <span style={styles.infoLabel}>Odometer:</span>
              <span>{`${commafy(vehicleData.mileage)} ${mileageUnits}`}</span>
            </li>

            <li>
              <Dotdotdot clamp={1}>
                <strong>
                  {(localization[getConfig('localization')].color || 'Color') +
                    ':'}{' '}
                </strong>
                {vehicleData.exteriorColor || dataPlaceholder}
              </Dotdotdot>
            </li>
            <li>
              <strong>Drivetrain: </strong>
              {vehicleData.drive || dataPlaceholder}
            </li>

            <li>
              <Dotdotdot clamp={1}>
                <strong>Engine:</strong> {vehicleData.engine || dataPlaceholder}
              </Dotdotdot>
            </li>
            <li>
              {getConfig('localization') === 'en-uk' && (
                <div>
                  <strong>Listed Price: </strong>
                  {localization[getConfig('localization')].currency}
                  <span>{commafy(vehicleData.retailBookValue.toFixed(2))}</span>
                </div>
              )}
            </li>
            <li>
              {getConfig('localization') === 'en-uk' && (
                <div>
                  <strong>Cap Clean: </strong>
                  {localization[getConfig('localization')].currency}
                  <span>
                    {commafy(vehicleData.wholesaleBookValue.toFixed(2))}
                  </span>
                </div>
              )}
            </li>
            <li>
              {vehicleData.licenseNumber && (
                // && getConfig('localization') === 'en-uk'
                <div>
                  <strong>Cap ID: </strong>
                  {vehicleData.licenseNumber}
                </div>
              )}
            </li>

            <li>{this.renderDaysInMarketplace()}</li>
          </ul>
        </div>

        <div className="col-xs-7">
          <ul className="list-unstyled">
            {vehicleData.itemCity && (
              <li>
                <Dotdotdot clamp={1}>
                  <strong>Location:</strong> {vehicleData.itemCity},{' '}
                  {vehicleData.itemState}
                </Dotdotdot>
              </li>
            )}
            {this.renderDistance()}
            {!hideSeller && (
              <li>
                <Dotdotdot clamp={1}>
                  <strong>Seller: </strong>
                  {vehicleData.sellerName || dataPlaceholder}
                </Dotdotdot>
              </li>
            )}
            {this.renderWatchlistAddedDate()}
            {this.renderHighOffer()}
            <li>
              <CountdownTimerVLI
                vehicleData={this.props.vehicleData}
                onCountdownChange={() => null}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }

  renderDistance() {
    const { city, state, zipcode, address } = this.props.userSelectedLocation;
    const { latitude, longitude } = this.props.vehicleData;

    const { distance } = this.state;

    if (!distance) {
      return null;
    }

    const distanceUnits = findDistanceUnits(this.props.marketplaceFeatures);
    const distanceIsTooFar =
      (distanceUnits === 'mi' && distance > 3300) ||
      (distanceUnits === 'km' && distance > 5311);

    const distanceRounded =
      distance < 100 ? distance.toFixed(1) : Math.round(distance);

    let distanceText =
      latitude || longitude
        ? `${commafy(distanceRounded)} ${distanceUnits}`
        : 'N/A';

    let displayAddress = ``;
    if (city && state) {
      displayAddress = `${city}, ${state}`;
    } else if (zipcode) {
      displayAddress = zipcode;
    } else if (address) {
      displayAddress = address
        .replace(/, USA/g, '')
        .replace(/USA/g, '')
        .replace(/[\d*]/g, '');
    }

    if (displayAddress) distanceText += ` from ${displayAddress}`;
    if (DISABLE_DISTANCE_IN_DEV && process.env['MY_NODE'] === 'development') {
      distanceText = 'DEV MODE';
    }

    return (
      <li>
        <span>
          <strong>Distance:</strong> {distanceText}
        </span>
      </li>
    );
  }

  renderHighOffer() {
    const { vehicleData } = this.props;

    if (!vehicleData.highOffer || vehicleData.highOffer === '0') return null;

    return (
      <div>
        <span style={styles.infoLabel}>Current High Offer:</span>
        <span
          style={{
            color: getTemplate(this.props.template, 'misc.orange'),
          }}
        >
          {commafyCurrency(vehicleData.highOffer)}
        </span>
      </div>
    );
  }

  renderWatchlistAddedDate() {
    const watchlistAddedDate =
      this.props.watchlistObject[this.props.vehicleData.id];

    if (watchlistAddedDate) {
      const timezone = jstz.determine().name();
      const adj = isDST() ? '07:00' : '08:00';
      const formattedDate = moment
        .tz(`${watchlistAddedDate}-${adj}`, timezone)
        .format('ddd M/D/YYYY hh:mm a');

      return (
        <div>
          <strong style={{ marginRight: 3 }}>Added to Watchlist:</strong>
          <span>{formattedDate}</span>
        </div>
      );
    }
  }

  renderButtons() {
    return (
      <div 
        // style={styles.buttons}
        className='vehicle-list-view_item__buttons'
      >
        {this.renderBuyNowPrice()}
        {this.renderBuyNowButton()}
        {this.renderBidButton()}
        {this.renderOfferButton()}
        {this.renderWatchlistButton()}
      </div>
    );
  }
  /**
   * 
   * @description 2024.09.04 <br/> ***Disabled*** Per **MP-45**
   *  Replacing with Offer Modal until various bugs and UX issues are addressed
   */
  renderOfferInline() {
    return null;
    /* if (!showOfferButton(this.props.vehicleData)) {
      return null;
    }

    const hideFees = (this.props.marketplaceFeatures.features || '')
      .split(',')
      .includes('548');

    return (
      <div 
        // style={styles.inline}
        className='vehicle-list-view_item__inline'
      >
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label className="sr-only" htmlFor="bid-amount">
              Amount
            </label>
            <div className="input-group">
              <div className="input-group-addon">
                {localization[getConfig('localization')].currency}
              </div>
              <input
                id="offer-amount"
                className="form-control"
                type="text"
                autoComplete="off"
                pattern="[1-9][0-9]*"
                onInput={this.handleAmount}
                value={this.state.offerAmount}
                style={{ padding: '0px', height: '28px' }}
              />
            </div>
          </div>
          {!hideFees && (
            <Fees
              amount={this.state.offerAmount}
              type="1"
              itemId={this.props.vehicleData.id}
              userId={this.state.selectedAccount}
            />
          )}
          <div className="form-group">
            <label htmlFor="buyer-account">Buyer Account</label>
            <select
              className="form-control"
              id="buyer-account"
              style={{ padding: '0px', height: '25px' }}
              value={localStorage.getItem('selectedAccount') || ''}
              onChange={this.handleAccountSelection}
            >
              <option value="">Select Account</option>
              {this.loadAccounts()}
            </select>
          </div>
        </form>
      </div>
    ); */
  }

  renderBidButton() {
    if (!showBidButton(this.props.vehicleData)) {
      return null;
    }
    const { hasSubListings, subItemDetails } = this.props.vehicleData;
    const isBulkLot = hasSubListings && subItemDetails.length > 0;
    return (
      <button
        data-type={isBulkLot ? 'bulkBid' : 'bid'}
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardBidButton.backgroundColor'
          ),
        }}
        onClick={this.handleModal}
      >
        Place Bid
      </button>
    );
  }
  /**
   * 
   * @description **2024.09.04** - Per `MP-45`, renders Offer Modal until inline Offer issues are fixed.
   */
  renderOfferButton() {
    if (!showOfferButton(this.props.vehicleData)) {
      return null;
    }

    const disabled = !this.state.selectedAccount || !this.state.offerAmount;

    return (
      <button
        data-type="offer"
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardOfferButton.backgroundColor'
          ),
          // opacity: disabled ? 0.5 : 1,
        }}
        // disabled={disabled}
        onClick={this.handleModal}
        // onClick={this.handleOffer}
      >
        Place Offer
      </button>
    );
  }

  renderBuyNowButton() {
    if (!showBuyNowButton(this.props.vehicleData)) {
      return null;
    }

    return (
      <button
        data-type="buy"
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardBuyButton.backgroundColor'
          ),
        }}
        onClick={this.handleModal}
      >
        Buy Now
      </button>
    );
  }

  renderWatchlistButton() {
    const watchlistAddedDate =
      this.props.watchlistObject[this.props.vehicleData.id];
    const isInWatchlist = !!watchlistAddedDate;
    let formattedDate = '';

    if (isInWatchlist) {
      const timezone = jstz.determine().name();
      const adj = isDST() ? '07:00' : '08:00';
      formattedDate = moment
        .tz(`${watchlistAddedDate}-${adj}`, timezone)
        .format('ddd MM/DD/YY hh:mm a');
    }

    const dataAction = isInWatchlist ? 'remove' : 'add';
    const icon = isInWatchlist ? 'fa fa-check' : 'fa fa-plus';
    const title = isInWatchlist ? `Added ${formattedDate}` : 'Add to Watchlist';
    const { watchlistButtonDisabled } = this.state;

    return (
      <button
        title={title}
        data-action={dataAction}
        style={{
          ...styles.button,
          backgroundColor: getTemplate(
            this.props.template,
            'itemCardWatchlistButton.backgroundColor'
          ),
          opacity: watchlistButtonDisabled ? 0.5 : 1,
        }}
        onClick={
          watchlistButtonDisabled
            ? () => null
            : () => this.handleWatchlistAction(dataAction)
        }
      >
        <span className={icon} style={{ marginRight: 3, fontSize: '0.9em' }} />
        <span>Watchlist</span>
      </button>
    );
  }

  renderBulkLotBadge() {
    // top-left
    const { hasSubListings, subItemDetails } = this.props.vehicleData;
    const isBulkLot = hasSubListings && subItemDetails.length > 0;
    const hasLotNumberBadge = this.renderLotNumberBadge();

    if (isBulkLot) {
      return (
        <div
          className="bulklot-badge"
          style={{ top: hasLotNumberBadge ? 30 : 5 }}
        >
          <span>Bulk Lot</span>
        </div>
      );
    }
  }

  renderLotNumberBadge() {
    // top-left
    const value = this.props.vehicleData.lotNumber;
    if (getConfig('localization') === 'en-us' && value) {
      const text = value;
      return (
        <span
          className="lot-number badge"
          style={{
            backgroundColor: getTemplate(this.props.template, 'misc.medGray'),
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderHighBidBadge() {
    // DEPRECATED
    // bottom-right (above buy now)
    const value =
      this.props.vehicleData.highBid || this.props.vehicleData.startingBid;

    if (showBidButton(this.props.vehicleData) && value) {
      const text = `Bid: ${commafyCurrency(value)}`;
      return (
        <span
          className="vehicle-card-bid badge"
          style={{
            bottom: 5,
            backgroundColor: getTemplate(
              this.props.template,
              'itemCardBidBadge.backgroundColor'
            ),
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderHighBidderBadge() {
    // bottom-right (above buy now)
    const { vehicleData, userProfile } = this.props;

    if (showBidButton(vehicleData)) {
      const { bidIncrement, highBid, highBidderAccountId, startingBid } =
        vehicleData;

      const accounts = (
        userProfile.user ? userProfile.user.accountList || [] : []
      ).map(account => Number(account.accountId));

      const isBidder = accounts.includes(Number(highBidderAccountId));

      const nextBid = highBid
        ? Math.max(
            Number(highBid || 0) + Number(bidIncrement || 0),
            Number(startingBid || 0)
          )
        : Number(startingBid || 0);

      const text = isBidder
        ? `You: ${commafyCurrency(Number(highBid))}`
        : nextBid
        ? `Next Bid: ${commafyCurrency(nextBid)}`
        : null;

      const style = {
        bottom: this.renderRetailPriceBadge() ? 25 : 5,
        backgroundColor: getTemplate(
          this.props.template,
          'itemCardBidBadge.backgroundColor'
        ),
      };

      if (getConfig('bidButtonAltColor') && String(text).startsWith('Next')) {
        style.backgroundColor = getConfig('bidButtonAltColor');
      }

      if (text) {
        return (
          <span className="vehicle-card-bid badge" style={style}>
            {text}
          </span>
        );
      }
    }
  }

  renderCRBadge() {
    // bottom-left
    const value = this.props.vehicleData.rating;
    if (this.props.vehicleData.hasCR && value) {
      const text = `CR ${value}`;
      return (
        <span
          className="cr-button badge"
          style={{
            backgroundColor: getTemplate(this.props.template, 'misc.medGray'),
          }}
        >
          {text}
        </span>
      );
    }
  }

  render360Badge() {
    // bottom-left (above cr)
    const has360 =
      this.props.spinCars[(this.props.vehicleData.vIN || '').toUpperCase()];

    if (has360) {
      const text = ` 360°`;
      return (
        <span
          className="view-360 badge"
          style={{
            backgroundColor: getTemplate(this.props.template, 'misc.darkBlue'),
            bottom: this.renderCRBadge() ? 30 : 5,
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderRetailPriceBadge() {
    // bottom-right (this is an either-or with Buy Now price)
    const features = (this.props.marketplaceFeatures.features || '').split(',');
    const retailPriceEnabled = features.includes('547');
    const {
      feedPrice,
      outrightPrice,
      listingStatusId,
      isMPSellingLive,
      eventFeatures,
    } = this.props.vehicleData;
    const isViewOnlyEvent = String(eventFeatures).split(',').includes('17');

    if (
      retailPriceEnabled &&
      feedPrice &&
      (!outrightPrice || outrightPrice === '0') &&
      listingStatusId === 1 &&
      !isMPSellingLive &&
      !isViewOnlyEvent
    ) {
      const text = `Retail: ${commafyCurrency(feedPrice)}`;
      return (
        <span
          className="vehicle-card-price badge"
          style={{
            backgroundColor: getTemplate(
              this.props.template,
              'itemCardBuyBadge.backgroundColor'
            ),
          }}
        >
          {text}
        </span>
      );
    }
  }

  renderBuyNowPrice() {
    if (showBuyNowButton(this.props.vehicleData)) {
      const text = `Buy Now: ${commafyCurrency(
        this.props.vehicleData.outrightPrice
      )}`;
      return (
        <div
          style={{
            textAlign: 'center',
            marginTop: '0',
            marginBottom: 3,
            fontSize: 14,
            fontWeight: 'bolder',
            color: '#016B19',
          }}
        >
          {text}
        </div>
      );
    }
  }

  determineModalId = itemId => {
    // NOTE - modals don't work very well when the id contains white space in the modal id
    return `${(this.props.category || '').replace(/ +/g, '')}${
      itemId || this.props.vehicleData.id
    }`;
  };
  renderModals(isBulkLot) {
    const modalId = this.determineModalId();

    return (
      <>
        <BidModal
          modalId={modalId}
          item={this.props.vehicleData}
          loadData={this.props.loadData}
          navigate={this.props.navigate}
          isOpen={this.state.isBidModalOpen}
          onClose={() => this.setState({ isBidModalOpen: false })}
        />
        <BuyModal
          modalId={modalId}
          item={this.props.vehicleData}
          loadData={this.props.loadData}
          navigate={this.props.navigate}
          isOpen={this.state.isBuyNowModalOpen}
          onClose={() => this.setState({ isBuyNowModalOpen: false })}
        />
        <OfferModal
          modalId={modalId}
          item={this.props.vehicleData}
          loadData={this.props.loadData}
          navigate={this.props.navigate}
          offerAmount={0}
          offerId={0}
          offerType={"Offer"}
          requiredAmount={0}
          isOpen={this.state.isOfferModalOpen}
          onClose={() => this.setState({ isOfferModalOpen: false })}
        />
        {isBulkLot && (
          <BulkLotBidModal
            modalId={modalId}
            item={this.props.vehicleData}
            loadData={this.props.loadData}
            bulkLotItems={this.state.bulkLotItems}
            navigate={this.props.navigate}
            isOpen={this.state.isBulkBidModalOpen}
            onClose={() => this.setState({ isBulkBidModalOpen: false })}
          />
        )}
        {isBulkLot && (
          <BulkLotItemsModal
            modalId={modalId}
            bulkLotItems={this.state.bulkLotItems}
            isOpen={this.state.isBulkLotItemsModalOpen}
            onClose={() => this.setState({ isBulkLotItemsModalOpen: false })}
            navigate={this.props.navigate}
          />
        )}
      </>
    );
  }

  renderDynamicItem() {
    const { userProfile, vehicleData } = this.props;

    if (!userProfile.user || !vehicleData) {
      return null;
    }

    const YMMT = [
      vehicleData.year || '',
      vehicleData.make || '',
      vehicleData.model || '',
      vehicleData.series || '',
    ].join(' ');

    return (
      <div 
        className='vehicle-list-view_item__container'
        // style={styles.container}
      >
        {this.renderModals()}
        {this.renderImageBox()}
        <div 
          // style={styles.vehicleInfo}
          className='vehicle-list-view_item__vehicle_info '
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                id="header_title"
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  lineHeight: 2,
                  cursor: 'pointer',
                }}
                onClick={this.handleCardClick}
              >
                {YMMT}
              </div>
            </div>
            {[
              (vehicleData.attributes || [])
                .filter(attribute => attribute.inDetail)
                .map((attribute, index) => {
                  const { label, value } = getAttributeLabelAndValue(attribute);
                  return (
                    <div key={index}>
                      <span style={styles.infoLabel}>{label}</span>
                      <span>{value || 'N/A'}</span>
                    </div>
                  );
                }),
            ]}
            <div>
              {vehicleData.itemCity && (
                <div>
                  <strong>Location:</strong> {vehicleData.itemCity},{' '}
                  {vehicleData.itemState}
                </div>
              )}
              <ul className="list-unstyled">{this.renderDistance()}</ul>
              {getConfig('showListingStatus') &&
                vehicleData.listingStatus !== '' && (
                  <span>{vehicleData.listingStatus}</span>
                )}
              {this.renderWatchlistAddedDate()}
              <CountdownTimerVLI
                vehicleData={this.props.vehicleData}
                onCountdownChange={() => this.setState({})}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            // top right
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginRight: 18,
            marginTop: 10,
          }}
        >
          {this.renderLights(vehicleData.lights)}
          {this.renderQRCode(vehicleData.vIN)}
        </div>

        {this.renderOfferInline()}
        {this.renderButtons()}
      </div>
    );
  }

  renderStaticItem() {
    if (!this.props.userProfile.user || !this.props.vehicleData) {
      return null;
    }

    return (
      <div 
        className='vehicle-list-view_item__container'
        // style={styles.container}
      >
        {this.renderModals()}
        {this.renderImageBox()}
        {this.renderVehicleInfo()}
        {this.renderOfferInline()}
        {this.renderButtons()}
      </div>
    );
  }

  renderBulkLotItem() {
    const { vehicleData } = this.props;
    if (!this.props.userProfile.user || !this.props.vehicleData) {
      return null;
    }

    return (
      <div 
        // style={{ ...styles.container, boxShadow: bulkLotBoxShadow }}
        style={{boxShadow: bulkLotBoxShadow}}
        className='vehicle-list-view_item__container'
      >
        {this.renderModals(true)}
        {this.renderImageBox(true)}
        <div
          // style={{ ...styles.vehicleInfo, cursor: 'pointer' }}
          className='vehicle-list-view_item__vehicle_info'
          style={{ cursor: 'pointer' }}
          onClick={this.handleCardClick}
        >
          <div>
            <div>
              <span style={styles.rowLabel}>Bulk Lot:</span>
              <span
                data-type="bulkList"
                style={{
                  textDecoration: 'underline',
                  color: '#6492bd',
                  cursor: 'pointer',
                }}
                onClick={this.handleModal}
              >
                {vehicleData.subItemDetails.length + 1} Items
              </span>
            </div>

            <div>
              {vehicleData.itemCity && (
                <div>
                  <strong>Location:</strong> {vehicleData.itemCity},{' '}
                  {vehicleData.itemState}
                </div>
              )}

              <div>
                <div>{vehicleData.bulkLotDescription || 'N/A'}</div>
                <div style={styles.rowLabel}>Bidding on Multiple Items</div>
                <br />
              </div>

              {getConfig('showListingStatus') &&
                vehicleData.listingStatus !== '' && (
                  <span>{vehicleData.listingStatus}</span>
                )}
              {this.renderWatchlistAddedDate()}
              <CountdownTimerVLI
                vehicleData={this.props.vehicleData}
                onCountdownChange={() => this.setState({})}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            // top right
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginRight: 18,
            marginTop: 10,
          }}
        >
          {this.renderLights(vehicleData.lights)}
          {this.renderQRCode(vehicleData.vIN)}
        </div>

        {this.renderOfferInline()}
        {this.renderButtons()}
      </div>
    );
  }

  render() {
    const { hasSubListings, subItemDetails } = this.props.vehicleData;
    const isBulkLot = hasSubListings && subItemDetails.length > 0;
    const isDynamic = this.state.dynamicData;

    return isBulkLot
      ? this.renderBulkLotItem()
      : isDynamic
      ? this.renderDynamicItem()
      : this.renderStaticItem();
  }
}

VehicleListItem.propTypes = {
  loadData: PropTypes.func,
  offer: PropTypes.object,
  vehicleData: PropTypes.object.isRequired,
};

const styles = {
  container: {
    /* .vehicle-list-view_item__container */
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    borderRadius: 6,
    minHeight: imageHeight,
    height: imageHeight,
    marginBottom: 10,
    width: '95%',
  },
  imageBox: {
    /* .vehicle-list-view_item__image_wrapper */
    width: 290, // to keep 4:3
    flexShrink: 0,
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageContainer: {
    /* .vehicle-list-view_item__image_container */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#000',
    objectFit: 'contain',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    height: '100%',
    width: '100%',
  },
  image: {
    /* .vehicle-list-view_item__image_container */
    backgroundColor: '#000',
  },
  dynamicImage: {
    /* .vehicle-list-view_item__image_dynamic */
    backgroundColor: '#fff',
  },
  badge: {
    position: 'absolute',
    display: 'inline-block',
    minWidth: 10,
    padding: '4px 6px',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    borderRadius: 2,
    zIndex: 9,
  },
  vehicleInfo: {
    /* .vehicle-list-view_item__vehicle_info */
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  infoLabel: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  inline: {
    /* .vehicle-list-view_item__inline */
    width: 150,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    borderLeft: '1px solid #f1f1f1',
  },
  buttons: {
    /* .vehicle-list-view_item__buttons */
    width: 150,
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    borderLeft: '1px solid #f1f1f1',
  },
  button: {
    border: 'none',
    borderRadius: 3,
    outline: 'none',
    padding: '4px 5px',
    margin: 2,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    width: '100%',
    color: '#fff',
  },
  rowLabel: {
    fontWeight: 'bold',
    marginRight: 4,
    color: '#787878',
  },
};

const mapStateToProps = state => {
  const {
    geocode,
    item,
    marketplaceFeatures,
    marketplaces,
    offer,
    spinCars,
    template,
    unauthorizedEventIds,
    userProfile,
    userSelectedLocation,
    watchlistES,
    watchlistUserItems,
    watchlistObject,
  } = state.entities;

  return {
    geocode,
    item,
    marketplaceFeatures,
    marketplaces,
    offer,
    spinCars,
    template,
    unauthorizedEventIds,
    userProfile,
    userSelectedLocation,
    watchlistES,
    watchlistUserItems,
    watchlistObject,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loadAddWatchList,
    loadElasticSearchDistance,
    loadItem,
    loadItemOffer,
    loadRemoveWatchList,
    loadTransportationTypes,
    loadWatchlistES,
    loadWatchlistUserItems,
    resetOffer,
    resetBidHistory,
    loadWatchlistObject,
  })
)(VehicleListItem);
